<template>
  <div>
    <b-table
      :items="lista"
      :fields="campos"
      responsive="sm"
      :filter="filtro"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      striped
      :busy="loading"
    >
      <template #table-busy class="text-center">
        <b-spinner />
      </template>
      <template #cell(nome)="row">
        <div class="text-left">
          {{row.item.nome}}
        </div>
      </template>
      <template #cell(actions)="row">
        <b-button size="sm" variant="warning" @click="editar(row.item)"
          ><i class="fa fa-edit"></i> Editar</b-button
        >
        <b-button size="sm" variant="danger" @click="excluir(row.item)"
          ><i class="fa fa-trash"></i> Excluir</b-button
        >
      </template>
    </b-table>
     <b-pagination
      v-model="paginacao.pagina_atual"
      :total-rows="lista.length"
      :per-page="paginacao.registros_por_pagina"
    >
    </b-pagination>

  </div>
</template>

<script>
import UsuariosLib from "@/libs/UsuariosLib";

export default {
  props: {
    onFiltrar: Function,
    filtro: String,
    onEditar: Function,
  },
  data() {
    return {
      loading: false,
      lista: [],
      campos: [
        { key: "id", label: "Código", sortable: true },
        { key: "nome", label: "Nome", sortable: true },
        { key: "email", label: "E-mail", sortable: true },
        { key: "perfil", label: "Perfil", sortable: true },
        { key: "actions", label: "" },
      ],
      paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1,
      },
    };
  },
  mounted() {
    this.carregar();
  },
  methods: {
    async filtrar(filtro) {
      this.filtro = filtro;
      if (this.$props.onFiltrar) {
        this.$props.onFiltrar(this.filtro);
      }
    },
    async carregar() {
      this.loading = true;
      await this.$nextTick();
      this.lista = await UsuariosLib.get();

      this.loading = false;
    },
    async editar(item) {
      if (item) {
        if (this.$props.onEditar) {
          this.$props.onEditar(item);
        }
      }
    },
    async excluir(user) {
      if (user) {
        this.$swal
          .fire({
            title: "Você tem certeza que deseja excluir este usuário?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim. Excluir!",
            cancelButtonText: "Não.",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              const result = await UsuariosLib.del(user);
              if (result && result.success === true) {
                this.$swal
                  .fire({
                    title: "Usuário excluído com sucesso!",
                    icon: "success",
                    toast: true,
                    timer: 1500,
                    position: "top-end",
                    showConfirmButton: false,
                  })
                  .then(() => {
                    this.carregar();
                  });
              } else {
                this.$swal.fire({
                  title: "Erro ao excluir usuário!",
                  icon: "error",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });
              }
            }
          });
      }
    },
  },
};
</script>

<style>
</style>