<template>
  <div>
    <div class="row">
      <div class="col-9">
        <label>Nome</label>
        <b-input
          placeholder="Nome"
          v-model="usuario.nome"
          :state="validateState('nome')"
        />
        <b-form-invalid-feedback>
          Informe um nome válido</b-form-invalid-feedback
        >
      </div>
      <div class="col-3 text-center">
        <label>Situação</label>

        <b-form-checkbox
          v-model="usuario.situacao"
          value="1"
          unchecked-value="0"
          name="check-button"
          switch
          class="mt-2"
        >
          <span v-if="!usuario.situacao || usuario.situacao == 0">Inativo</span>
          <span v-if="usuario.situacao && usuario.situacao == 1">Ativo</span>
        </b-form-checkbox>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-sm-6">
        <label>Usuário</label>
        <b-input
          placeholder="Usuário"
          v-model="usuario.usuario"
          :state="validateState('usuario')"
        />
        <b-form-invalid-feedback>
          Informe um nome de usuário válido</b-form-invalid-feedback
        >
      </div>
      <div class="col-12 col-sm-6">
        <label>Senha</label>
        <b-input
          placeholder="Senha"
          type="password"
          :state="validateState('senha')"
          v-model="usuario.senha"
        />
        <b-form-invalid-feedback>
          Informe uma senha válida</b-form-invalid-feedback
        >
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <label>E-mail</label>
        <b-input
          placeholder="Email"
          v-model="usuario.email"
          :state="validateState('email')"
        />
        <b-form-invalid-feedback>
          Informe um e-mail válido</b-form-invalid-feedback
        >
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-sm-6">
        <label>Perfil</label>
        <b-select
          :options="opc_perfil"
          v-model="usuario.perfil"
          :state="validateState('perfil')"
        />
        <b-form-invalid-feedback> Selecione um perfil!</b-form-invalid-feedback>
      </div>
      <div class="col-12 col-sm-6">
        <select-clinicas v-model="usuario.clinica_id"></select-clinicas>
        <b-form-invalid-feedback>
          Selecione uma clinica!</b-form-invalid-feedback
        >
      </div>
      <div class="col-12 col-sm-6">
        <label>Entidade</label>
        <select-paciente
          :showBotaoAdd="false"
          :showBotaoEdit="false"
          :selecionado="usuario.entidade_id"
          @paciente="onEntidade"
          setNullOnEmpty
        ></select-paciente>
        <!-- <v-select
          :options="opc_entidades"
          v-model="usuario.entidade_id"
          label="razao_social"
          :reduce="ent=>ent.id"
        /> -->
        <b-form-invalid-feedback>
          Selecione uma entidade !</b-form-invalid-feedback
        >
      </div>
      <div class="col-12 col-sm-6 text-left">
        <b-checkbox switch v-model="usuario.coringa" value="1" unchecked-value="0">Usuário coringa <small>(pode alterar a clínica)</small></b-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import Validadores from "../../helpers/Validadores.js";
import UsuariosLib from "@/libs/UsuariosLib";
import EntidadesLib from "@/libs/EntidadesLib";
import { niveis } from "../../helpers/niveisUsuario";
import SelectClinicas from "../../components/common/SelectClinicas.vue";
import SelectPaciente from "../../components/common/SelectPaciente.vue";
export default {
  components: {
    SelectClinicas,
    SelectPaciente,
  },
  props: {
    usuarioProp: Object,
    setSalvando: Function,
    closeModal: Function,
  },
  watch: {
    "$props.usuarioProp": function() {
      if (this.$props.usuarioProp) {
        this.usuario = this.$props.usuarioProp;
      }
    },
    usuario: {
      deep: true,
      handler() {
        this.validar();
      },
    },
    salvando: function() {
      if (this.$props.setSalvando) {
        this.$props.setSalvando(this.salvando);
      }
    },
  },
  data() {
    return {
      usuario: {},
      opc_perfil: niveis,

      opc_entidades: [],
      hasErros: [],
      salvando: false,
    };
  },
  methods: {
    setUsuario(usuario) {
      this.usuario = Object.assign({}, usuario);
    },
    onEntidade(entidade) {
      if (!entidade || !entidade?.id) {
        this.usuario.entidade_id = null;
        return;
      }

      this.usuario.entidade_id = entidade.id;
    },
    async carregarEntidades() {
      this.opc_entidades = (await EntidadesLib.get()).entidades;
    },
    async salvar() {
      if (this.validar()) {
        this.salvando = true;
        const result = await UsuariosLib.store(this.usuario);
        console.log(result);
        if (result && result.success === true) {
          this.$swal
            .fire({
              toast: true,
              title: "Concluído",
              text: "Usuário salvo com sucesso!",
              icon: "success",
              position: "top-end",
              timer: 1500,
              showConfirmButton: false,
              timerProgressBar: true,
            })
            .then(() => {
              return true;
            });
          this.salvando = false;

          return true;
        } else {
          this.salvando = false;

          this.$swal.fire({
            toast: true,
            title: "Ops! Erro ao salvar!",
            icon: "error",
            position: "top-end",
            timer: 1500,
            showConfirmButton: false,
            timerProgressBar: true,
          });
          return false;
        }
      }
      return false;
    },
    validar() {
      this.clearErros();

      if (Object.keys(this.usuario).length > 0) {
        this.addOrSetError(
          "nome",
          !Validadores.string.IsNullOrEmpty(this.usuario.nome)
        );
        this.addOrSetError(
          "usuario",
          !Validadores.string.IsNullOrEmpty(this.usuario.usuario)
        );
        this.addOrSetError(
          "email",
          Validadores.string.IsEmail(this.usuario.email)
        );
        if (this.usuario.senha != "") {
          this.addOrSetError(
            "senha",
            Validadores.string.validaSenha(this.usuario.senha)
          );
        }
        this.addOrSetError(
          "perfil",
          !Validadores.string.IsNullOrEmpty(this.usuario.perfil)
        );
        this.addOrSetError(
          "clinica_id",
          !Validadores.string.IsNullOrEmpty(this.usuario.clinica_id)
        );
      }
      return this.hasErros.filter((x) => x.state == true).length >= 4;
    },
    clearErros() {
      this.hasErros.map((it) => (it.state = null));
    },
    addOrSetError(campo, error) {
      let ob = this.hasErros.find((x) => x.campo == campo);
      if (!ob) {
        this.hasErros.push({ campo, state: error });
      } else {
        ob.state = error;
      }
    },
    validateState(field) {
      let ob = this.hasErros.find((x) => x.campo == field);
      if (!ob) {
        return null;
      } else {
        return ob.state;
      }
    },
  },
  mounted() {
    //this.carregarEntidades()
  },
};
</script>

<style></style>
