<template>
  <div>
    <BarraCrud :onFiltrar="filtrar" :botaoNovo="true" :onNovo="onNovo" />
    <usuarios-table :filtro="filtro" ref="usuariosTable" :onEditar="onEditar" />
    <b-modal
      id="modal-usuario"
      :title="`${usuario.id != '' ? 'Edição de ' : 'Novo'} usuário`"
      no-fade
      content-class="modalDireito"
      @ok="salvar"
    >
      <usuarios-form
        :usuarioProp="usuario"
        ref="usuForm"
        :setSalvando="setSalvando"
      />
      <template #modal-footer>
        <b-button variant="primary" @click="salvar" :disabled="salvando">
          <span v-if="!salvando"> <i class="fa fa-save"></i> Salvar </span>
          <span v-if="salvando">
            <b-spinner type="grow" small />
            Salvando
          </span>
        </b-button>
        <b-button variant="secondary" @click="$bvModal.hide('modal-usuario')"
          ><i class="fa fa-times"></i> Cancelar</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import BarraCrud from "@/components/common/BarraCrud";
import UsuariosTable from "../../components/Usuarios/UsuariosTable.vue";
import UsuariosForm from "../../components/Usuarios/UsuariosForm.vue";
export default {
  components: {
    BarraCrud,
    UsuariosTable,
    UsuariosForm,
  },
  data() {
    return {
      salvando: false,
      filtro: "",
      usuario: {
        nome: "",
        email: "",
        senha: "",
        perfil: "",
        id: "",
        situacao: 0,
        clinica_id: null,
        entidade_id: null
      },
    };
  },
  methods: {
    setSalvando(val) {
      this.salvando = val;
    },
    filtrar(filtro) {
      this.filtro = filtro;
    },
    onNovo() {
      // this.usuario.nome = "";
      // this.usuario.email = "";
      // this.usuario.senha = "";
      // this.usuario.perfil = "";
      // this.usuario.id = "";
      this.usuario = {};

      this.$bvModal.show("modal-usuario");
    },
    async salvar(evt) {
      evt.preventDefault();
      const res = await this.$refs.usuForm.salvar();
      if (res === true) {
        this.$bvModal.hide("modal-usuario");
        this.$refs.usuariosTable.carregar();
      }
    },
    async onEditar(item) {
      this.usuario = item;
      this.$bvModal.show("modal-usuario");
      await this.$nextTick();
      this.$refs.usuForm.setUsuario(item);
    },
  },
};
</script>

<style>
</style>